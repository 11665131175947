import React from "react"
import TwoColTextImage from "../components/content/TwoColTextImage"
import Layout from "../components/layout"
import Seo from "../components/seo"

import firstImage from "../images/_X2_8903_.png"
import secondImage from "../images/WAITING_087_1_cy copy.png"
import thirdImage from "../images/Freemasons__X2_8609.png"

const Thanks = () => {
  const images = [
    <img
      src={firstImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[248/441] object-cover rounded-2xl w-[26.25%] max-w-[26.25%] relative`}
    />,
    <img
      src={secondImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[382/679] object-cover rounded-2xl mb-8 w-[40.75%] max-w-[40.75%] relative`}
    />,
    <img
      src={thirdImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[281/500] object-cover rounded-2xl mb-16 w-[30%] max-w-[30%] relative`}
    />,
  ]
  return (
    <Layout>
      <Seo title="Register - Thank you - Email verification " description="" />
      <div className={`bg-theme-blue-light`}>
        <div className={`container`}>
          <TwoColTextImage
            title="Thank You!"
            content={
              <p>
                Please check your inbox for a confirmation e-mail, we&#39;re
                just double checking you&#39;re on our VIP supplier list.
              </p>
            }
            images={images}
            showAppLinks={false}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Thanks
