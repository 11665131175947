import React from "react"
import play from "../../images/play-store.png"
import app from "../../images/app-store.png"

const TwoColTextImage = ({ title, content, images, showAppLinks }) => {
  return (
    <div className={`flex flex-wrap pt-20 md:pt-40 pb-10 md:pb-28`}>
      <div
        className={`w-full md:w-1/3 lg:w-[18%] lg:ml-1/12 lg:mr-[6%] mb-10 md:mb-16 md:max-w-1/3 lg:max-w-[18%] relative z-10 md:pr-6 lg:pr-0 md:pt-14`}
      >
        <h1>{title}</h1>
        <div>{content}</div>

        {showAppLinks && (
          <>
            <a
              href="https://play.google.com/store/apps/details?id=com.mbplc.mabsupplierdinewithus"
              target="_blank"
              rel="noreferrer"
              className={`block w-48 mb-3 text-[0px] no-underline`}
            >
              <img src={play} alt="Get it on Google Play" />
            </a>
            <a
              href="https://apps.apple.com/gb/app/mabsupplierdinewithus/id6443455025?ls=1"
              target="_blank"
              rel="noreferrer"
              className={`block w-48 mb-3 text-[0px] no-underline`}
            >
              <img src={app} alt="Download on the App Store" />
            </a>
          </>
        )}
      </div>
      <div className={`w-full md:w-2/3 lg:w-7/12 md:max-w-2/3 lg:max-w-7/12`}>
        <div className={`flex gap-3 items-end relative`}>
          <span
            className={`block bg-theme-blue-light w-1/2 aspect-square absolute -top-28 left-1/12 z-0 rounded-full`}
          />
          {images.map(image => {
            return image
          })}
        </div>
      </div>
    </div>
  )
}

export default TwoColTextImage
